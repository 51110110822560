import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNativeSock from 'vue-native-websocket'


Vue.config.productionTip = false
Vue.use(VueNativeSock, 'wss://rd-az.way2ar.com/ws', {reconnection: true, reconnectionAttempts: 20, reconnectionDelay: 3000})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
