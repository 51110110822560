<template>
  <div id="app" class="wrapper">
    <div class="wrapper__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>


<style>
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}
@font-face {
  font-family: 'Mont';
  src: local('Mont Regular'), local('Mont-Regular'),
      url('./assets/fonts/Mont-Regular.woff2') format('woff2'),
      url('./assets/fonts/Mont-Regular.woff') format('woff'),
      url('./assets/fonts/Mont-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mont';
  src: local('Mont Bold'), local('Mont-Bold'),
      url('./assets/fonts/Mont-Bold.woff2') format('woff2'),
      url('./assets/fonts/Mont-Bold.woff') format('woff'),
      url('./assets/fonts/Mont-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Mont';
  src: local('Mont Semi Bold'), local('Mont-Semi-Bold'),
      url('./assets/fonts/Mont-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Mont-SemiBold.woff') format('woff'),
      url('./assets/fonts/Mont-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
body {
  font-family: 'Mont';
  background-color: #FFCE00;
  color: #fff;
  transition: all 300ms;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
  min-height: 100vh;
  max-width: 100%;
}
</style>