import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    phrases: [],
    response: {},
    paused: false,
  },

  getters: {
    phrases: state => state.phrases,
    paused: state => state.paused,
  },

  actions: {
    async pushTag({ commit }, payload) {
      commit('PUSH_TAG', payload);
    },
    async removeTagLocaly({ commit }, payload) {
      commit('REMOVE_TAG', payload);
    },
    async getPause({ commit }) {
      const paused = await instance.get(`${settings.API_URL}/getPaused`);
      commit('SET_PAUSE', paused.data.paused);
    },
    async setPause({ commit }, payload) {
      await instance.post(`${settings.API_URL}/setPaused`, payload);
      commit('SET_PAUSE', payload);
    },
    async setPauseLocal({ commit }, payload) {
      commit('SET_PAUSE', payload);
    },
    async getPhrases({ commit }) {
      const phrases = await instance.get(`${settings.API_URL}/getPhrases`);
      commit('SET_PHRASES', phrases.data);
    },
    async addTag({ commit }, payload) {
      const response = await instance.post(`${settings.API_URL}/addTag`, payload);
      commit('SET_RESPONSE', response.data);
    },
    async removeTag({ commit }, payload) {
      const response = await instance.post(`${settings.API_URL}/removeTag`, payload);
      commit('SET_RESPONSE', response.data)
    },
  },

  mutations: {
    PUSH_TAG(state, payload) {
      let tag = {
        id: payload.id,
        text: payload.text,
      }
      state.phrases.find(p => p.id == payload.phraseID).tags.push(tag);
    },
    REMOVE_TAG(state, payload) {
      let index = state.phrases.find(p => p.id == payload.phraseID).tags.findIndex(t => t.id == payload.id);
      if (index !== -1) {
        state.phrases.find(p => p.id == payload.phraseID).tags.splice(index, 1);
      }
    },
    SET_PAUSE(state, paused) {
      state.paused = paused;
    },
    SET_PHRASES(state, phrases) {
      state.phrases = phrases;
    },
    SET_RESPONSE(state, response) {
      state.response = response;
    },
  },
};