import Vue from 'vue';
import Vuex from 'vuex';

import phrases from './modules/phrases';


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    phrases,
  },
  strict: debug,
});
