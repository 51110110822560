<template>
  <div class="scene-box">
    <Snow />
    <div v-for="phrase in phrases" :key="phrase.id">
      <div class="scene-wrapper" v-show="activeScreen == phrase.id">
        <div class="scene">
          <transition-group name="fade" tag="div">
            <div :class="`tag-wrapper tag-wrapper-${tag.id}`" v-for="tag in phrase.tags" :key="tag.id" >
              <div :class="`tag tag-${tag.id}`"><span>{{tag.text}}</span></div>
            </div>
          </transition-group>
          <div class="main">
            <div class="main__top" :style="`box-shadow: 0px 0px 30px 30px #${phrase.color};`">
              <div class="main__top-title">жизненно<br>важно</div>
              <div class="main__top-subtitle" :style="`color: #${phrase.color}`">{{phrase.text}}...</div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import gsap from "gsap";
import Snow from '@/components/Snow';
export default {
  name: 'Main',

  data() {
    return {
      activeScreen: 0,
      timeline: null,
      hasNewTag: false,
      newTagId: null,
      starts: [],
    }
  },

  computed: {
    paused() {
      return this.$store.getters.paused
    },
    phrases() {
      return this.$store.getters.phrases
    },
    activePhrase() {
      return this.phrases.find(p => p.id == this.activeScreen);
    },
  },

  mounted() {
    this.timeline = gsap.timeline();
    setInterval(() => {
      this.changeScreen();
    }, 10000)
    this.startAnimation();
    this.$store.dispatch('getPause').then(() => {
      if (this.paused) {
        this.$store.dispatch('setPause', true);
        this.timeline.pause();
      } else {
        this.$store.dispatch('setPause', false);
        this.timeline.play();
      }
    })
    setInterval(() => {
      this.$socket.send('heartbeat');
    }, 20000)
    this.$options.sockets.onmessage = (event) => {
      let message = JSON.parse(event.data);
      if (message.message == "newTag") {
        this.addWord(message.tag)
        if (this.activeScreen !== message.tag.phraseID) {
          this.activeScreen = message.tag.phraseID;
        }
        this.hasNewTag = true;
        this.newTagId = message.tag.id;
        setTimeout(() => {
          this.$store.dispatch('setPauseLocal', true);
        }, 1000)
      }
      if (message.message == "paused") {
        this.$store.dispatch('setPauseLocal', true);
        setTimeout(() => {
          this.timeline.pause();
        }, 1000)
      }
      if (message.message == "unpaused") {
          setTimeout(() => {
            this.timeline.play();
          }, 100)
          this.$store.dispatch('setPauseLocal', false);
          if (this.hasNewTag) {
            this.runWord(this.newTagId)
            this.hasNewTag = false;
            this.newTagId = null;
          }
      }
      if (message.message == "removeTag") {
        this.$store.dispatch('removeTagLocaly', message.tag)
      }
    } 
  },

  components: {
    Snow,
  },
  methods: {
    changeScreen() {
      if (!this.paused) {
        let index = this.phrases.findIndex(ph => ph.id == this.activeScreen);
        if (this.phrases.length-1 == index) {
          this.activeScreen = this.phrases[0].id
        } else {
          let next = index+1;
          this.activeScreen = this.phrases[next].id
        }
      }
    },
    startAnimation() {
      if (!this.starts.includes(this.activeScreen)) {
        let delay = 0;
        this.$store.dispatch('getPhrases').then(() => {
            if (this.activeScreen == 0) {
              this.activeScreen = this.phrases[0].id;
            }
              this.activePhrase.tags.forEach((item) => {
                let time =  this.random(18, 34);
                this.timeline.set(".tag-wrapper-"+item.id, {xPercent: 0, yPercent: 0, x: 0, y: 0, transformOrigin: `${this.random(-30, 30)}px ${this.random(-20, 20)}px`}, 0);
                this.timeline.set(".tag-"+item.id, {scale: 0}, 0);
                this.timeline.to(".tag-wrapper-"+item.id, {x: this.random(200, 300), y: this.random(-50, -100), duration: 1, delay: delay}, 0);
                this.timeline.to(".tag-wrapper-"+item.id, {rotation: -360, ease: "none", repeat: -1, duration: time, delay: delay}, 0);
                this.timeline.set(".tag-"+item.id, {scale: 0, opacity: 1,  ease: "none",  repeat: 0, duration: 1, delay: delay}, 0);
                this.timeline.to(".tag-"+item.id, {scale: 1, repeat: 0, duration: 1, delay: delay});
                this.timeline.to(".tag-"+item.id, {rotation: 360, ease: "none", repeat: -1, duration: time, delay: delay}, 0);
                delay = delay+0.8;
              })
              this.phrases.forEach((phrase) => {
                let delay2 = 0;
                if (this.activePhrase.id !== phrase.id) {
                  phrase.tags.forEach((item) => {
                    let time =  this.random(18, 34);
                    this.timeline.set(".tag-wrapper-"+item.id, {xPercent: 0, yPercent: 0, x: 0, y: 0, transformOrigin: `${this.random(-30, 30)}px ${this.random(-20, 20)}px`}, 0);
                    this.timeline.set(".tag-"+item.id, {scale: 0}, 0);
                    this.timeline.to(".tag-wrapper-"+item.id, {x: this.random(200, 300), y: this.random(-50, -100), duration: 1, delay: delay2}, 0);
                    this.timeline.to(".tag-wrapper-"+item.id, {rotation: -360, ease: "none", repeat: -1, duration: time, delay: delay2}, 0);
                    this.timeline.set(".tag-"+item.id, {scale: 0,  ease: "none",  repeat: 0, duration: 1, delay: delay2}, 0);
                    this.timeline.to(".tag-"+item.id, {scale: 1, opacity: 1, repeat: 0, duration: 1, delay: delay2});
                    this.timeline.to(".tag-"+item.id, {rotation: 360, ease: "none", repeat: -1, duration: time, delay: delay2}, 0);
                    delay2 = delay2+0.8;
                  })
                }
              })
            this.starts.push(this.activeScreen);
        })
      }
    },
    addWord(tag) {
      let that = this;
      this.$store.dispatch('pushTag',tag).then(() => {
        gsap.to(".tag-"+tag.id, {
          scale: 2,
          opacity: 1,
          duration: 0.5,
          repeat: 0,
          fontSize: 30, y: 120,
          onComplete: function() {
            setTimeout(() => {
              let color = that.phrases.find(ph => ph.id == that.activeScreen).color;
              let element = document.getElementsByClassName('tag-'+tag.id);
              let elementSpan = document.querySelector('.tag-'+tag.id+' span');
              element[0].classList.add("tag--with-bg");
              elementSpan.style.boxShadow = `0px 0px 30px 30px #${color}`;
              elementSpan.style.background = `#${color}`;
            }, 300)
          }
        });
      })
    },
    runWord(id) {
        setTimeout(() => {
          gsap.to(".tag-"+id, {scale: 5, duration: 1, repeat: 0, fontSize: 20, y: 0});
          let time =  this.random(18, 34);
          gsap.to(".tag-wrapper-"+id, {transformOrigin: `${this.random(-30, 30)}px ${this.random(-20, 20)}px`});
          this.timeline.to(".tag-wrapper-"+id, {xPercent: 0, yPercent: 0, x: 0, y: 0,}, 0);
          this.timeline.to(".tag-wrapper-"+id, {rotation: -360, ease: "none", repeat: -1, duration: time}, 0);
          this.timeline.to(".tag-wrapper-"+id, {x: this.random(300, 400), y: this.random(-50, 100), scale: 1, duration: 1, delay: 0.5}, 0);
          gsap.to(".tag-"+id, {scale: 1, repeat: 0, duration: 1, delay: 0.5});
          this.timeline.to(".tag-"+id, {rotation: 360, ease: "none", repeat: -1, duration: time}, 0);
          let elementSpan = document.querySelector('.tag-'+id+' span');
          let element = document.getElementsByClassName('tag-'+id);
          element[0].classList.remove("tag--with-bg");
          elementSpan.style.boxShadow = null;
          elementSpan.style.background = null;
        }, 500)
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    randomPlusOrMinus() {
      let number =  Math.round(Math.random()) * 2 - 1;
      if (number > 0) {
        return '+'
      } else {
        return '-'
      }
    }
  },
  watch: {
    activeScreen(val) {
      let color = this.phrases.find(ph => ph.id == val).color;
      document.body.style.background = `#${color}`;
    }
  }
}
</script>

<style lang="sass">
  .scene-wrapper
    position: relative
    z-index: 100
    overflow: hidden
    width: 100vw
    height: 100vh
  .scene-box
    position: relative
    z-index: 100
    overflow: hidden
    width: 100vw
    height: 100vh
  .scene
    width: 577px
    height: 391px
    margin: auto
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    transform: scale(1.5)
  .tag
    font-size: 20px
    opacity: 0
    &-wrapper
      position: absolute
      left: 0
      right: 0
      bottom: 100px
      margin: auto
      text-align: center
      padding: 0px 105px
      
  .main
    display: flex
    justify-content: center
    align-items: center
    height: 391px
    transform: scale(1.5)
    &__top
      width: 280px
      height: 115px
      background-color: #fff
      border-radius: 120px
      margin: auto
      text-align: center
      color: #819596
      font-weight: bold
      font-size: 22px
      padding-top: 22px
      line-height: 1
      transition: all 300ms
      &-subtitle
        font-size: 30px
        color: #F7B500
  .snowflake
    position: absolute
    width: 10px
    height: 10px
    background: linear-gradient(white, white)
    border-radius: 50%
    filter: drop-shadow(0 0 10px white)
  .fade-enter-active, .fade-leave-active
    opacity: 1
  .fade-enter, .fade-leave-to
    opacity: 0
  .screens-enter-active, .screens-leave-active
    opacity: 1
    transition: all 500ms
  .screens-enter, .fade-screens-to
    opacity: 0
    transition: all 500ms
</style>