<template>
  <div class="manage">
    <div class="error" v-if="error">
      Максимальная длина фразы - 44 символа
    </div>
    <div class="manage__header">
      <div class="manage__logo">
        <img src="@/assets/img/logo.svg" alt="">
      </div>
      <div class="manage__logo-2">
        <img src="@/assets/img/logo-2.svg" alt="">
      </div>
      <div class="manage__control" v-if="!isEdit">
        <img src="@/assets/img/pause.svg" @click="setPause" alt="" v-if="paused === false">
        <img src="@/assets/img/play.svg" @click="unsetPause" alt="" v-if="paused == true">
      </div>
      <div class="manage__control" v-else>
        <img src="@/assets/img/close.svg" @click="isEdit = false" alt="">
      </div>
    </div>
    <template v-if="!isEdit">
      <div class="manage__search">
        <div class="manage__search-title">Введите продолжение фразы</div>
        <div class="manage__search-field">
          <input type="text" v-model="tag">
          <button :class="{disabled: !isActiveSubmit}" @click="addTag"><img src="@/assets/img/submit.svg" alt=""></button>
        </div>
      </div>
      <div class="manage__categories">
        <div class="manage__categories-item" v-for="phrase in phrases" :key="phrase.id">
          <a href="javascript:void(0)" @click="setPhrase(phrase)" v-if="activePhrase == phrase.id" :style="`background-color:#${phrase.color}`">{{phrase.text}}</a>
          <a href="javascript:void(0)" @click="setPhrase(phrase)" v-else>{{phrase.text}}</a>
        </div>
      </div>
      <div class="manage__button">
        <a href="javascript:void(0)" @click="isEdit = true">Редактировать теги на экране</a>
      </div>
    </template>
    <template v-else>
      <div class="manage__columns">
        <div class="manage__column" v-for="phrase in phrases" :key="phrase.id" :style="`background-color: #${phrase.color}`">
          <div class="manage__column-title">{{phrase.text}}</div>
          <div class="manage__column-list">
            <div class="manage__column-list-item" v-for="tag in phrase.tags" :key="tag.id">{{tag.text}} <img src="../assets/img/close.svg" alt="" @click="removeTag(tag.id)"></div>
          </div>
        </div>
        <div class="manage__column"></div>
        <div class="manage__column"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Manage',
  data() {
    return {
      error: false,
      tag: '',
      activePhrase: 0,
      isEdit: false,
    }
  },
  computed: {
    paused() {
      return this.$store.getters.paused
    },
    phrases() {
      return this.$store.getters.phrases
    },
    isActiveSubmit() {
      if (this.activePhrase !== 0 && this.tag.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store.dispatch('getPhrases');
    this.$store.dispatch('getPause');
  },
  methods: {
    setPhrase(phrase) {
      if (this.activePhrase == phrase.id) {
        this.activePhrase = 0;
      } else {
        this.activePhrase = phrase.id;
      }
    },
    addTag() {
      if (this.isActiveSubmit) {
        if (this.tag.length < 44) {
          let payload = {
            text: this.tag,
            phrase_id: this.activePhrase,
          }
          this.$store.dispatch('addTag', payload).then(() => {
            this.tag = '';
            this.activePhrase = 0;
            this.$store.dispatch('getPhrases');
            this.setPause();
          })
        } else {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 2000)
        }
      }
    },
    removeTag(id) {
      let payload = {
        id: id
      }
      this.$store.dispatch('removeTag', payload).then(() => {
        this.$store.dispatch('getPhrases');
      })
    },
    setPause() {
      let payload = {
        paused: true,
      }
      this.$store.dispatch('setPause', payload).then(() => {
        this.$store.dispatch('getPause');
      })
    },
    unsetPause() {
      let payload = {
        paused: false,
      }
      this.$store.dispatch('setPause', payload).then(() => {
        this.$store.dispatch('getPause');
      })
    },
  }
}
</script>

<style lang="sass">
  .error
    position: fixed
    top: 0
    left: 0
    right: 0
    margin: auto
    background-color: red
    color: #fff
    font-size: 30px
    text-align: center
    padding: 10px 20px
  .manage
    background-color: #fff
    background-image: url("../assets/img/bg.jpg")
    min-height: 100vh
    width: 100%
    background-size: cover
    background-position: 50% 50%
    &__columns
      display: flex
      flex-wrap: wrap
      width: 90%
      margin: auto
      padding-top: 40px
      justify-content: space-between
    &__column
      width: 30%
      margin-bottom: 20px
      border-radius: 10px
      padding: 10px
      text-align: center
      &-list
        height: 280px
        overflow: auto
        &-item
          font-size: 16px
          display: flex
          align-items: center
          justify-content: center
          margin-bottom: 6px
          img
            width: 20px
            margin-left: 10px
      &-title
        font-size: 21px
        font-weight: bold
        border-bottom: 1px solid rgba(255,255,255,0.5)
        padding-bottom: 5px
        margin-bottom: 10px
    &__button
      a
        width: 409px
        height: 55px
        background-color: #1C355E
        color: #fff
        font-size: 21px
        font-weight: 600
        display: block
        text-align: center
        line-height: 55px
        border-radius: 10px
        position: fixed
        bottom: 63px
        right: 42px
    &__header
      display: flex
      align-items: center
      justify-content: space-between
      padding-left: 43px
      padding-right: 37px
      padding-top: 41px
    &__categories
      display: flex
      align-items: center
      flex-wrap: wrap
      justify-content: space-between
      width: 465px
      margin: auto
      margin-top: 35px
      &-item
        width: 225px
        height: 43px
        margin-bottom: 20px
        &.active
          a
            background-color: #5FD0DF !important
            border-color: #fff !important
        a
          display: block
          border-radius: 20px
          border: 2px solid #819596
          width: 100%
          height: 100%
          background-color: #819596
          text-align: center
          line-height: 41px
          font-weight: bold
          font-size: 24px
          text-transform: lowercase
    &__logo
      margin-top: 16px
      &-2
        margin-left: -136px
        margin-top: -22px
    &__search
      &-title
        text-align: center
        font-size: 35px
        font-weight: 600
        padding-top: 176px
      &-field
        padding-left: 44px
        padding-right: 44px
        position: relative
        button
          position: absolute
          width: 77px
          height: 77px
          right: 57px
          top: 4px
          bottom: 0
          margin: auto
          transition: all 300ms
          &.disabled
            filter: grayscale(1)
          img
            width: 100%
            height: 100%
        input
          width: 100%
          height: 99px
          border-radius: 45px
          background-color: #fff
          margin-top: 8px
          color: #000
          padding-left: 40px
          padding-right: 40px
          font-size: 50px
</style>